<template>
  <div id="app">
    <First/>
  </div>
</template>

<script>
import First from './components/First.vue'

export default {
  name: 'App',
  components: {
    First
  }
}
</script>