<template>
  <div>
    <div class="container">
      <div class="col-xs-12">
        <div
          class="text-center villa"
          style="padding-top: 30px; padding-bottom: 30px"
        >
          <img
            src="@/assets/loog_villa_kaboom_buffet_colorido.png"
            alt="Vila"
          />
        </div>
      </div>
    </div>

    <div class="container">
      <div class="col-xs-12">
        <div class="text-center" v-for="(itens,index) in item" :key="index">
          <div class="pb-4" :class="itens.class">
            <button
              :onclick="itens.link"
              type="button"
              :class="itens.btn"
            >
              <b-icon :icon="itens.icon" scale="1"></b-icon>{{itens.name}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <Clouds />
  </div>
</template>

<script>
import Clouds from "./Clouds.vue";
export default {
  name: "Index",
  components: {
    Clouds,
  },
  data(){
    return{
      item:[
        {
          name:'Instagram',
          link:'location.href="https://www.instagram.com/villakaboombuffet"',
          class:'instagram one',
          icon:'instagram',
          btn:'btn btn-outline-light'
        },
        {
          name:'WhatsApp',
          link:'location.href="https://wa.me/5571997000928"',
          class:'whatsapp two',
          icon:'whatsapp',
          btn:'btn btn-outline-light'
        },
        {
          name:'Localização',
          link:'location.href="https://goo.gl/maps/hKUfpBVgwxoTGyyw9"',
          class:'maps three',
          icon:'map',
          btn:'btn btn-outline-light'
        },
        {
          name:'Orçamentos',
          link:'location.href="https://api.whatsapp.com/send?phone=557197000928&text=Ol%C3%A1%2C%20gostaria%20de%20um%20or%C3%A7amento%20para%20minha%20festinha%20%F0%9F%98%8D"',
          class:'promos four shake',
          icon:'lightning',
          btn:'btn btn-outline-light'
        }
        ],
    }
  }
};
</script>

